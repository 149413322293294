<template>
  <div class="">
    <div class="card">
      <div class="card-body">
        <h4
          class="card-title"
          :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
        >
          {{ $t("view.messages_info") }}
        </h4>

        <div class="tab-danger pt-3">
          <div v-if="$props.name" class="form-group col-12">
            <label
              for="input1"
              class="col-form-label"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
            >
              {{ $t("view.name") }}
            </label>
            <input
              id="input1"
              type="text"
              class="form-control"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
              v-model="$props.name"
              :disabled="true"
            />
          </div>

          <div v-if="$props.mobile" class="form-group col-12">
            <label
              for="input2"
              class="col-form-label"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
            >
              {{ $t("app.mobile") }}
            </label>
            <input
              id="input2"
              type="text"
              class="form-control"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
              v-model="$props.mobile"
              :disabled="true"
            />
          </div>

          <div v-if="$props.email" class="form-group col-12">
            <label
              for="input2"
              class="col-form-label"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
            >
              {{ $t("view.email") }}
            </label>
            <input
              id="input2"
              type="text"
              class="form-control"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
              v-model="$props.email"
              :disabled="true"
            />
          </div>

          <div v-if="$props.question" class="form-group col-12">
            <label
              for="input2"
              class="col-form-label"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
            >
              {{ $t("app.question") }}
            </label>
            <textarea
              id="input2"
              rows="9"
              class="form-control"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
              v-model="$props.question"
              :disabled="true"
            >
            </textarea>
          </div>

          <div class="form-group col-12">
            <label
              for="input2"
              class="col-form-label"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
            >
              {{ $t("app.answer") }}
            </label>
            <textarea
              id="input2"
              rows="9"
              class="form-control"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
              v-model="row.answer"
            >
            </textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormMessage",
  props: ["name", "email", "question", "answer"],
  data() {
    return {
      row: {
        answer: this.$props.answer,
      },
    };
  },
  mounted() {},
  methods: {
    //
    onChange() {
      let data = {
        answer: this.row.answer,
      };

      this.$emit("formChange", data);
    },
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
